import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { CreativeTimLogo } from "components/Icons/Icons";
import { Separator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
// import { getConfiguration } from "redux/actions/ConfigurationAction";
import { setConfiguration } from "redux/slices/ConfigurationSlice";
import { ChevronDownIcon, ChevronUpIcon, HamburgerIcon } from "@chakra-ui/icons";
// import { ChevronDownIcon, ChevronUpIcon } from "react-icons/md";


const SidebarContent = ({ logoText, routes }) => {
  const configurationList = useSelector((state) => state.configuration);
  const { configuration } = configurationList;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (configuration.called) {
  //     dispatch(getConfiguration());
  //   }
  // }, [configuration, dispatch]);

  let location = useLocation();
  const [state, setState] = React.useState({});

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const createLinks = (routes, configuration) => {
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        const isOpen = state[prop.state];
        const toggleCollapse = () => {
          setState((prevState) => ({
            ...prevState,
            [prop.state]: !prevState[prop.state],
          }));
        };
  
        return (
          <div key={prop.name}>
            <Button
              onClick={toggleCollapse}
              bg={isOpen ? activeBg : "transparent"}
              color={isOpen ? activeColor : inactiveColor}
              fontWeight="bold"
              mb={{
                xl: "12px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              w="100%"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              justifyContent="space-between"
              alignItems="center"
              pl={isOpen ? "8px" : "16px"} // Adjust the left padding based on open/closed state
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={isOpen ? configuration.color : inactiveBg}
                    color={isOpen ? "white" : configuration.color}
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text my="auto" fontSize="sm">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
              <Icon
                as={isOpen ? ChevronUpIcon : ChevronDownIcon}
                boxSize="4"
                color={isOpen ? activeColor : inactiveColor}
              />
            </Button>
            {isOpen && createLinks(prop.views, configuration)}
          </div>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path} key={prop.name}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={configuration.color}
                    color="white"
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              py="12px"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color={configuration.color}
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };

  const links = <>{createLinks(routes, configuration)}</>;

  return (
    <Box
      pt="25px"
      mb="12px"
      overflowY="auto"
      maxH="calc(100vh)"
      css={{
        "&::-webkit-scrollbar": {
          width: "6px",
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#4A5568",
          borderRadius: "8px",
        },
      }}
    >
      <Link
        href={`/`}
        // target="_blank"
        display="flex"
        lineHeight="100%"
        mb="30px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <img
          src={configuration.primary_logo}
          alt="Company logo"
          width="200px"
          height="30px"
          style={{ marginRight: "10px" }}
        />
      </Link>
      <Separator />
      <Stack direction="column" mb="40px">
        <Box>{links}</Box>
      </Stack>
    </Box>
  );
};

export default SidebarContent;
