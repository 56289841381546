import axios from "axios";
import {
    setIsLoading,
    setError,
    setProjects,
    setNameError,
    setStartDateError,

    setIsModalOpen,
    setSelectedData,
    setCurrentPage,
    setLastPage,
    setNextPage,
    setPrevPage,
    setIsEdit
}
    from '../slices/Project';

import { baseurl } from 'variables';


export const getProjects = (currentPage, token) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await axios.get(`${baseurl}/get-project`, {
            page: currentPage,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        console.log('Projects', res, currentPage);
        dispatch(setProjects(res.data.data.project));
        dispatch(setIsLoading(false));

        if (res.data.data.last_page === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(false));
            dispatch(setNextPage(false));
        } else if (res.data.data.last_page === currentPage) {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch((false));
        } else if (currentPage === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        } else {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        }

    } catch (error) {
        dispatch(setIsLoading(false));
        console.log('Projects error', error, currentPage);
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}


export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at projects ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};


export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};


export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};


export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));
    dispatch(setNameError(''));
    dispatch(setStartDateError(''));
};


export const handleSave = (isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;
        const start_date = document.getElementsByName('start_date')[0].value;
        const end_date = document.getElementsByName('end_date')[0].value;

        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }
        let formData = {
            name: name,
        };


        if (!start_date) {
            dispatch(setStartDateError(`Start Date is required`));
            return;
        } else {
            dispatch(setStartDateError(''));
            formData = {
                ...formData,
                start_date: start_date,
            };
        }

        if (end_date) {
            formData = {
                ...formData,
                end_date: end_date,
            };
        }

        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                id: initialData.id,
                ...formData,
            };
            console.log('save response', formData, isEdit, initialData);
            const response = await axios.post(`${baseurl}/add-project`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log('Response from Project update', response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-project`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response.data);
        }
        dispatch(getProjects(1, token));
        dispatch(onClose());
    } catch (error) {
        console.log('Error Project Update', error);
    }
};