import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorMode } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { handleSave } from "redux/actions/ShopTypeAction";

const ModalShopType = ({ isOpen, onClose, isEdit, initialData }) => {
    const dispatch = useDispatch();
    const { colorMode } = useColorMode();
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;
        
    const shopTypeModalList = useSelector((state) => state.shop_type);
    const {
        nameError,
    } = shopTypeModalList;


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEdit ? 'Edit Shop Type' : 'Create Shop Type'}</ModalHeader>
                <ModalBody>
                    <FormControl mt={4} isRequired isInvalid={nameError !== ''}>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="Enter name" name="name" defaultValue={initialData?.name} />
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                        onClick={() => dispatch(handleSave(isEdit, initialData, onClose, token))}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="ghost" onClick={() => dispatch(onClose())} colorScheme="red">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ModalShopType;