import {combineReducers, configureStore} from '@reduxjs/toolkit';
import configuration from './slices/ConfigurationSlice';
import projects from './slices/Project';
import shop_type from './slices/ShopType';
import contact from './slices/ContactListSlice';

const reducer = combineReducers({
    configuration,
    projects,
    shop_type,
    contact,
})

export default configureStore({reducer})