// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import ProjectView from "./components/ProjectView";

function Projects() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <ProjectView
          title={"Projects"}
          captions={["Sr.", "Name", "Start Date", "End Date", "actions"]}
        />
    </Flex>
  );
}

export default Projects;
