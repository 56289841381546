import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorMode } from "@chakra-ui/react";
import selectStyles from "components/Layout/StyleSelectNew";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectNew from 'react-select';
import { handleSave } from "redux/actions/ProjectsActions";

const ModalProject = ({ isOpen, onClose, isEdit, initialData }) => {
    const dispatch = useDispatch();
    const { colorMode } = useColorMode();
    const isDarkMode = colorMode === 'dark';
    const styles = selectStyles(isDarkMode, colorMode);
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;
        
    const projectModalList = useSelector((state) => state.projects);
    const {
        nameError,
        startDateError,
    } = projectModalList;


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEdit ? 'Edit Project' : 'Create Project'}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <FormControl mt={4} isRequired isInvalid={nameError !== ''}>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="Enter name" name="name" defaultValue={initialData?.name} />
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt={4} isRequired isInvalid={startDateError !== ''}>
                        <FormLabel>Start Date</FormLabel>
                        <Input type="date" placeholder="Select Start Date" name="start_date" defaultValue={initialData?.start_date} />
                        <FormErrorMessage>{startDateError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>End Date</FormLabel>
                        <Input type="date" placeholder="Select end Date" name="end_date" defaultValue={initialData?.end_date} />
                        <FormErrorMessage></FormErrorMessage>
                    </FormControl>

                </ModalBody>
                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                        onClick={() => dispatch(handleSave(isEdit, initialData, onClose, token))}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="ghost" onClick={() => dispatch(onClose())} colorScheme="red">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ModalProject;