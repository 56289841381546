import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    contactList: [],
    nameError: '',
    phoneError: '',
    addressError: '',
    emailError: '',
    salaryError: '',

    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    lastPage: 0,
    isEdit: false,
};


export const contactListSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setContactList: (state, { payload }) => {
            state.contactList = payload;
        },
        setNameError: (state, { payload }) => {
            state.nameError = payload;
        },
        setPhoneError: (state, { payload }) => {
            state.phoneError = payload;
        },
        setAddressError: (state, { payload }) => {
            state.addressError = payload;
        },
        setEmailError: (state, { payload }) => {
            state.emailError = payload;
        },
        setSalaryError: (state, { payload }) => {
            state.salaryError = payload;
        },
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setNextPage: (state, { payload }) => {
            state.nextPage = payload;
        },
        setPrevPage: (state, { payload }) => {
            state.prevPage = payload;
        },
        setLastPage: (state, { payload }) => {
            state.lastPage = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },
    }
})

export const { setIsLoading, setError, setContactList, setNameError, setPhoneError, setEmailError, setAddressError, setSalaryError, setIsModalOpen, setSelectedData, setCurrentPage, setLastPage, setNextPage, setPrevPage, setIsEdit } = contactListSlice.actions;
export default contactListSlice.reducer;

export const contactListSelector = (state) => state.contact;