import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

const DeleteConfirmation = ({ api, id, onClose, onDeleteSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const cancelRef = React.useRef();

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      // Perform the delete action using an API
    //   const response = await axios.delete(`${api}/${id}`);
      // Handle the delete success
      onDeleteSuccess();
      toast({
        title: "Delete Success",
        description: "The item has been deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Handle the delete error
      toast({
        title: "Delete Error",
        description: "An error occurred while deleting the item.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <AlertDialog
      isOpen={true}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirm Delete
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this item?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
              isLoading={isLoading}
              loadingText="Deleting"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteConfirmation;
