import React, { useState } from "react";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  chakra,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { SigninApi } from "redux/actions/ConfigurationAction";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";


function SignIn() {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const configurationList = useSelector((state) => state.configuration);
  const { configuration, usernameError, passwordError, token } = configurationList;


  useEffect(() => {
    if (token !== null) {
      history.push('/admin/dashboard');
    }
  }, []);

  const confTextColor = !configuration.color ? "teal.200" : configuration.color
  const backgroundImage = configuration.background
  return (
    <Flex
      h="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundImage={`url(${backgroundImage})`}>
      <Flex
        bgColor="blackAlpha.700" a
        direction="column"
        borderRadius={'xl'}
        w={{ base: "100%", md: "25%", lg: "25%" }}
        p="48px"
      >
        <img
          src={configuration.primary_logo}
          alt="Company logo"
          style={{ margin: 'auto' }}
          width="250px"
          height="40px"
        />
        <FormControl isRequired isInvalid={usernameError !== ''}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color={"white"}>
            Phone No
          </FormLabel>
          <Input
            borderRadius="15px"
            mb="24px"
            name="username"
            bgColor={'whiteAlpha.500'}
            fontSize="sm"
            type="text"
            placeholder="Enter Phone No (eg. 923001234567)"
            size="md"
          />
          <FormErrorMessage>{usernameError}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={passwordError !== ''}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color={"white"}>
            Password
          </FormLabel>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              size="md"
              borderRadius="15px"
              mb="24px"
              bgColor={'whiteAlpha.500'}
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter password'
              placeholderTextColor='red'
            />
            <InputRightElement>
              <IconButton
                icon={showPassword ? <ViewOffIcon size='lg' /> : <ViewIcon size='lg' />}
                onClick={handleShowClick}
                _hover={{
                  bgColor: 'transparent',
                }}
                _active={{
                  bgColor: 'transparent',
                }}
                bgColor={'transparent'}
                aria-label="View Secret"
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{passwordError}</FormErrorMessage>
        </FormControl>

        <Button
          fontSize="10px"
          type="submit"
          bg={confTextColor}
          w="100%"
          h="45"
          mb="20px"
          size="md"
          onClick={() => dispatch(SigninApi(toast, history))}
          color="white"
          mt="20px"
          _hover={{
            bg: "teal.200",
          }}
          _active={{
            bg: "teal.400",
          }}
        >
          SIGN IN
        </Button>
      </Flex>
    </Flex>
  );
}

export default SignIn;
