// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import ShopTypeView from "./components/ShopTypeView";

function ShopType() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <ShopTypeView
          title={"Shop Type"}
          captions={["Sr.", "Name",  "actions"]}
        />
    </Flex>
  );
}

export default ShopType;
