import axios from "axios";

import {
setIsLoading,
setError,
setShopTypes,
setNameError,
setIsModalOpen,
setSelectedData,
setIsEdit
} from '../slices/ShopType';

import { baseurl } from 'variables';

export const getShopType = (token) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await axios.get(`${baseurl}/get-shop-type-all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch(setShopTypes(res.data.data.shop_type));
        dispatch(setIsLoading(false));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}


export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at Shop Type ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};


export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};


export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};

export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));
    dispatch(setNameError(''));
};


export const handleSave = (isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;

        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }
        let formData = {
            name: name,
        };


        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                id: initialData.id,
                ...formData,
            };
            console.log('save response', formData, isEdit, initialData);
            const response = await axios.post(`${baseurl}/add-shop-type`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log('Response from Shop Type update', response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-shop-type`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response.data);
        }
        dispatch(getShopType(token));
        dispatch(onClose());
    } catch (error) {
        console.log('Error Shop Type Update', error);
    }
};