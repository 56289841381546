import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorMode } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSave } from "redux/actions/ContactListAction";


const ModalContactList = ({ isOpen, onClose, isEdit, initialData, title, type }) => {
    const dispatch = useDispatch();
    const { colorMode } = useColorMode();
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;
        
    const contactModalList = useSelector((state) => state.contact);
    const {
        nameError,
        phoneError,
        addressError,
        emailError,
        salaryError,
    } = contactModalList;


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEdit ? `Edit ${title}` : `Create ${title}`}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <FormControl mt={4} isRequired isInvalid={nameError !== ''}>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="Enter name" name="name" defaultValue={initialData?.name} />
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={addressError !== ''}>
                        <FormLabel>Address</FormLabel>
                        <Input type="text" placeholder="Enter Address" name="address" defaultValue={initialData?.address} />
                        <FormErrorMessage>{addressError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={phoneError !== ''}>
                        <FormLabel>Phone</FormLabel>
                        <Input type="text" placeholder="Enter phone" name="phone" defaultValue={initialData?.phone} />
                        <FormErrorMessage>{phoneError}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt={4} isInvalid={emailError !== ''}>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" placeholder="Enter email" name="email" defaultValue={initialData?.email} />
                        <FormErrorMessage>{emailError}</FormErrorMessage>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Description</FormLabel>
                        <Input type="text" placeholder="Enter Description" name="description" defaultValue={initialData?.description} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                        onClick={() => dispatch(handleSave(type, isEdit, initialData, onClose, token))}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="ghost" onClick={() => dispatch(onClose())} colorScheme="red">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ModalContactList;