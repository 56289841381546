// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import ContactListView from "./components/ContactListView";

function CustomerType() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <ContactListView
          title={"Customer"}
          captions={["Sr.", "Name", "address", "phone", "email", "description", "actions"]}
          tdLendth={7}
          type={{"is_customer": 1, "is_vendor": 0, "is_employee": 0, "is_user": 0, "is_broker": 0}}
        />
    </Flex>
  );
}


function VendorType() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <ContactListView
          title={"Vendor"}
          captions={["Sr.", "Name", "address", "phone", "email", "description", "actions"]}
          tdLendth={7}
          type={{"is_customer": 0, "is_vendor": 1, "is_employee": 0, "is_user": 0, "is_broker": 0}}
        />
    </Flex>
  );
}


function BrokerType() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <ContactListView
          title={"Broker"}
          captions={["Sr.", "Name", "address", "phone", "email", "description", "actions"]}
          tdLendth={7}
          type={{"is_customer": 0, "is_vendor": 0, "is_employee": 0, "is_user": 0, "is_broker": 1}}
        />
    </Flex>
  );
}
export {CustomerType, VendorType, BrokerType};
