// import
import Dashboard from "views/Dashboard/Dashboard";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
} from "components/Icons/Icons";
import { FaProjectDiagram, FaShoppingBasket } from "react-icons/fa";
import Projects from "views/Dashboard/Project";
import ShopType from "views/Dashboard/ShopType";
import { RiContactsBookFill, RiContactsFill, RiContactsLine, RiCustomerService2Fill } from "react-icons/ri";
import {CustomerType, VendorType, BrokerType} from 'views/Dashboard/ContactList';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/projects",
    name: "Projects",
    icon: <FaProjectDiagram color="inherit" />,
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/shop-type",
    name: "Shop Type",
    icon: <FaShoppingBasket color="inherit" />,
    component: ShopType,
    layout: "/admin",
  },
  {
    name: "Contact",
    category: "contact",
    icon: <RiContactsBookFill color="inherit" />,
    state: "contactCollapse",
    collapse: true,
    views: [
      {
        path: "/customer",
        name: "Customer",
        icon: <RiContactsFill color="inherit" />,
        secondaryNavbar: true,
        component: CustomerType,
        layout: "/admin",
      },
      {
        path: "/vendor",
        name: "Vendor",
        icon: <RiContactsLine color="inherit" />,
        secondaryNavbar: true,
        component: VendorType,
        layout: "/admin",
      },
      {
        path: "/broker",
        name: "Broker",
        icon: <RiCustomerService2Fill color="inherit" />,
        secondaryNavbar: true,
        component: BrokerType,
        layout: "/admin",
      }
    ],
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    icon: <DocumentIcon color="inherit" />,
    state: "pageCollapse",
    collapse: true,
    views: [
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/signin",
        name: "Sign In",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Sign Up",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
