import { createSlice } from '@reduxjs/toolkit';
import logo from './../../assets/img/logo.png';
import background from './../../assets/img/background.avif';

export const initialState = {
    configuration: {
        "color":"#006c85", 
        "primary_logo": logo,
        "background": background,
        "called": true},
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null,
    usernameError: '', 
    passwordError: ''
};

export const confSlice =  createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setConfiguration : (state, {payload}) => {
            state.configuration = payload;
        },
        setUser : (state, {payload}) => {
            state.user = payload;
        },
        setToken : (state, {payload}) => {
            state.token = payload;
        },
        setUsernameError : (state, {payload}) => {
            state.usernameError = payload;
        },
        setPasswordError : (state, {payload}) => {
            state.passwordError = payload;
        },

    }
})



export const { setConfiguration, setUser, setToken, setUsernameError, setPasswordError } = confSlice.actions;
export default confSlice.reducer;

export const confSelector = (state) => state.configuration;