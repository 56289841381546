const selectStyles = (isDarkMode, colorMode) => ({
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isDarkMode ? "gray.800" : "white",
    color: isDarkMode ? "white" : "black",
    borderColor: isFocused
      ? isDarkMode
        ? "var(--chakra-colors-whiteAlpha-300)"
        : "var(--chakra-colors-blue-400)"
      : isDarkMode
      ? "var(--chakra-colors-whiteAlpha-300)"
      : "var(--chakra-colors-gray-300)",
    "&:hover": {
      borderColor: isDarkMode
        ? "var(--chakra-colors-whiteAlpha-400)"
        : "var(--chakra-colors-gray-400)",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: isDarkMode ? "white" : "black",
    backgroundColor: isSelected
      ? "#3182ce"
      : isFocused
      ? "#4a5568"
      : isDarkMode
      ? "transparent"
      : "white",
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: isDarkMode ? "gray.700" : "white",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colorMode === "dark" ? "white" : "black",
    backgroundColor: colorMode === "dark" ? "blue.400" : "white",
  }),

  menuList: (styles) => ({
    ...styles,
    backgroundColor: isDarkMode ? "var(--chakra-colors-gray-700)" : "white",
  }),
});

export default selectStyles;
