import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    shopTypes: [],
    nameError: '',

    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    isEdit: false,
};


export const shopTypesSlice = createSlice({
    name: "shop_type",
    initialState,
    reducers: {
        setShopTypes: (state, { payload }) => {
            state.shopTypes = payload;
        },
        setNameError: (state, { payload }) => {
            state.nameError = payload;
        },

        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },
    }
})


export const { setIsLoading, setError, setShopTypes, setNameError, setIsModalOpen, setSelectedData, setIsEdit } = shopTypesSlice.actions;
export default shopTypesSlice.reducer;

export const shopTypeSelector = (state) => state.shop_type;