import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import { Provider } from 'react-redux';
import store from './redux/store';

// Check if the user has a token (e.g., from local storage)

// const isAuthenticated = !!localStorage.getItem('token');
const isAuthenticated = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
console.log('isAuthenticated index', isAuthenticated)
// HOC for authenticated routes
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated ? (
        <Redirect to="/auth/signin" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <ProtectedRoute path="/admin" component={AdminLayout} />
        <ProtectedRoute path="/rtl" component={RTLLayout} />
        <Redirect exact from="/" to={!isAuthenticated ?  "/auth/signin": "/admin/dashboard"} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
