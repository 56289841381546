import React, { useState } from "react";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import DeleteConfirmation from "components/Layout/DeleteModal";
import { useDispatch } from "react-redux";



const ShopTypeTableData = (props) => {
    const { id, index, name, onEdit } = props;
    const textColor = useColorModeValue("gray.700", "white");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const dispatch = useDispatch();
  
    const handleEdit = () => {
        dispatch(onEdit({id, index, name}));
      };
    
      const handleDelete = () => {
        setShowDeleteConfirmation(true);
      };
    
      const handleCancelDelete = () => {
        console.log('delete cancel onclose');
        setShowDeleteConfirmation(false);
      };
    
      const handleConfirmDelete = () => {
        console.log(`Deleting item with ID: ${id}`);
        setShowDeleteConfirmation(false);
      };

      return (
        <Tr>
          <Td>
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {index}
              </Text>
            </Flex>
          </Td>
          <Td>
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {name}
              </Text>
            </Flex>
          </Td>
          <Td>
            <IconButton
              icon={<EditIcon />}
              colorScheme="telegram"
              bgColor="orange.400"
              color={"white"}
              onClick={handleEdit}
              aria-label="Edit"
              size="sm"
              mr="3"
            />
            <IconButton
              icon={<DeleteIcon />}
              colorScheme="telegram"
              bgColor="red"
              color={"white"}
              onClick={handleDelete}
              aria-label="Delete"
              size="sm"
            />
            {showDeleteConfirmation && (
              <DeleteConfirmation
                api={id}
                id={id}
                onClose={handleCancelDelete}
                onDeleteSuccess={handleConfirmDelete}
              />
            )}
          </Td>
        </Tr>
      );
    }
    
export default ShopTypeTableData