import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    projects: [],
    nameError: '',
    startDateError: '',

    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    lastPage: 0,
    isEdit: false,
};


export const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setProjects: (state, { payload }) => {
            state.projects = payload;
        },
        setNameError: (state, { payload }) => {
            state.nameError = payload;
        },
        setStartDateError: (state, { payload }) => {
            state.startDateError = payload;
        },

        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setNextPage: (state, { payload }) => {
            state.nextPage = payload;
        },
        setPrevPage: (state, { payload }) => {
            state.prevPage = payload;
        },
        setLastPage: (state, { payload }) => {
            state.lastPage = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },
    }
})


export const { setIsLoading, setError, setProjects, setNameError, setStartDateError, setIsModalOpen, setSelectedData, setCurrentPage, setLastPage, setNextPage, setPrevPage, setIsEdit } = projectsSlice.actions;
export default projectsSlice.reducer;

export const projetcsSelector = (state) => state.projects;