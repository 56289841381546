import { Button, Flex, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getShopType } from 'redux/actions/ShopTypeAction';

import { handleEdit } from 'redux/actions/ShopTypeAction';
import { handleCloseModal } from 'redux/actions/ShopTypeAction';
import { handleOpenModal } from 'redux/actions/ShopTypeAction';
import ShopTypeTableData from './ShopTypeTableData';
import ModalShopType from './ShopTypeModal';

const ShopTypeView = ({ title, captions }) => {
    const dispatch = useDispatch();
    const textColor = useColorModeValue("gray.700", "white");
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;

    const shopTypeList = useSelector((state) => state.shop_type);
    const {
        isModalOpen,
        isLoading,
        selectedData,

        shopTypes,

        isEdit
    } = shopTypeList;

    // Get Shop Type List 
    useEffect(() => {
        dispatch(getShopType(token));
    }, []);

    return (
        <>
            {
                isLoading ? ( // Display loading spinner if data is still being fetched
                    <Flex justify="center" align="center" height="300px">
                        <Spinner size="xl" color="gray.400" />
                    </Flex>
                )
                    :
                    (
                        <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                            <CardHeader p='6px 0px 22px 0px'>
                                <Flex alignItems='center' justifyContent='space-between' flex={1}>
                                    <Text fontSize='xl' color={textColor} fontWeight='bold'>
                                        {title}
                                    </Text>
                                    <Button onClick={() => dispatch(handleOpenModal())} colorScheme='telegram' color={"white"} bgColor={configuration.color} mr='3px'>
                                        Add New Shop Type
                                    </Button>
                                </Flex>
                            </CardHeader>
                            <CardBody>
                                <Table variant='simple' color={textColor}>
                                    <Thead>
                                        <Tr>
                                            <Td colSpan={5} pl="0px">
                                                <Flex justifyContent={"flex-end"}>
                                                    <Input
                                                        width={"30%"}
                                                        mr={"0.5rem"}
                                                        type="text"
                                                        placeholder="Search"
                                                    />
                                                    <Button
                                                        color={"white"}
                                                        colorScheme="telegram"
                                                        bgColor={configuration.color}
                                                    >
                                                        Search
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>

                                        <Tr my='.8rem' pl='0px' color='gray.400'>
                                            {captions.map((caption, idx) => {
                                                return (
                                                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                                                        {caption}
                                                    </Th>
                                                );
                                            })}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {shopTypes.map((row, index) => {
                                            return (
                                                <ShopTypeTableData
                                                    key={row.id}
                                                    id={row.id}
                                                    index={index + 1}
                                                    name={row.name}
                                                    onEdit={handleEdit}
                                                />
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </CardBody>
                            {isModalOpen && <ModalShopType isOpen={isModalOpen} onClose={handleCloseModal} initialData={selectedData} isEdit={isEdit} />}
                        </Card>
                    )
            }
        </>
    )
}

export default ShopTypeView