import axios from "axios";

import {
    setIsLoading,
    setError,
    setContactList,
    setNameError,
    setPhoneError,
    setEmailError,
    setAddressError,
    setSalaryError,
    setIsModalOpen,
    setSelectedData,
    setCurrentPage, 
    setLastPage, 
    setNextPage, 
    setPrevPage,
    setIsEdit
}
    from '../slices/ContactListSlice';

import { baseurl } from "variables";


export const getContactList = (type, currentPage, token) => async (dispatch) => {
    console.log('type contact', type, {
        page: currentPage,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    dispatch(setIsLoading(true));
    try {
        const res = await axios.get(`${baseurl}/get-contact-list`, {
            page: currentPage,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch(setContactList(res.data.data.contact_list));
        dispatch(setIsLoading(false));

        if (res.data.data.last_page === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(false));
            dispatch(setNextPage(false));
        } else if (res.data.data.last_page === currentPage) {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch((false));
        } else if (currentPage === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        } else {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        }
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}

export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at Contact List ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};


export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};


export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};

export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));
    dispatch(setNameError(''));
    dispatch(setPhoneError(''));
    dispatch(setEmailError(''));
    dispatch(setAddressError(''));
    dispatch(setSalaryError(''));
};


export const handleSave = (type, isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;
        const phone = document.getElementsByName('phone')[0].value;
        const email = document.getElementsByName('email')[0].value;
        const description = document.getElementsByName('description')[0].value;
        const address = document.getElementsByName('address')[0].value;

        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }
        if (!phone) {
            dispatch(setPhoneError('Phone is required'));
            return;
        } else {
            dispatch(setPhoneError(''));
        }
        if (!address) {
            dispatch(setAddressError('Address is required'));
            return;
        } else {
            dispatch(setAddressError(''));
        }

        let formData = {
            name: name,
            address: address,
            phone: phone,
        };
        if (description)
        {
            formData = {
                ...formData,
                description: description,
            };
        }
        if (email)
        {
            formData = {
                ...formData,
                email: email,
            };
        }
        formData = {
            ...formData,
            ...type
        }
        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                id: initialData.id,
                ...formData,
            };
            console.log('save response', formData, isEdit, initialData);
            const response = await axios.post(`${baseurl}/add-contact-list`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log('Response from Contact List update', response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-contact-list`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response.data);
        }
        dispatch(getContactList(type, 1, token));
        dispatch(onClose());
    } catch (error) {
        console.log('Error Contact List Update', error);
    }
};